@import url(https://fonts.googleapis.com/css2?family=Righteous&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@800&display=swap);
html, body {
    font-family: 'Inter', sans-serif;
    padding: 0;
    margin: 0;
    background-color: #103483;
  }
  
  #root {
    height: 100vh;
  }
  
  * {
    box-sizing: border-box;
  }
  
  .header{
    display: flex;
    flex-direction: column;
    align-items: left;
    position: absolute;
    top: 0;
    left: 0;
    color: #FFFFFF;
    text-align: left;
    margin-left: 20px;
    font-size: 2.2em;
  }
  .header > h1, .header > h4, .header > h2{
    margin-bottom: 0;
  }
  .header > h4, .header > h2{
    margin-top: 10px;
  }

  .joinOuterContainer {
    display: flex;
    justify-content: center;
    text-align: center;
    height: 100vh;
    align-items: center;
    background: transparent url(/static/media/shiny_happy.9111f999.png) center right no-repeat padding-box;
    background-size: 38rem 47rem;
    margin-right: 10px;
}
  
  .joinInnerContainer {
    width: 20%;
  }
  
  .joinInput {
    border-radius: 5px;
    padding: 15px 20px;
    width: 100%;
    border: 1px solid black;
  }
  
  .heading {
    color: white;
    font-size: 2.5em;
    padding-bottom: 10px;
    border-bottom: 2px solid white;
  }
  
  .button {
      color: #fff !important;
      text-transform: uppercase;
      text-decoration: none;
      font-size: 17px;
      background: #2979FF;
      padding: 20px;
      border-radius: 5px;
      border: 2.6px solid #1A1A1D;
      display: inline-block;
      width: 100%;
      cursor: pointer;
  }
  
  .mt-20 {
    margin-top: 20px;
  }
  
  @media (min-width: 320px) and (max-width: 480px) {
    .joinOuterContainer {
      height: 100%;
    }
  
    .joinInnerContainer {
      width: 90%;
    }
    .header{
      display: none;
    }
    .heading{
      color: #000000;
      background-color: white;
      padding: 0;
      border-radius: 8px;
      opacity: 0.8;
    }
  
  
  }
  
  button:focus {
    outline: 0;
  }
  
.outerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #1A1A1D;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #FFFFFF;
    border-radius: 10px;
    height: 60%;
    width: 35%;
  }
  
  @media (min-width: 320px) and (max-width: 480px) {
    .outerContainer {
      height: 100%;
    }
  
    .container {
      width: 100%;
      height: 100%;
    }
  }
  
  @media (min-width: 480px) and (max-width: 1200px) {
    .container {
      width: 60%;
    }
  }
.infoBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #103483;
    border-radius: 4px 4px 0 0;
    height: 60px;
    width: 100%;
  }
  
  .leftInnerContainer {
    flex: 0.5 1;
    display: flex;
    align-items: center;
    margin-left: 5%;
    color: white;
  }
  
  .rightInnerContainer {
    display: flex;
    flex: 0.5 1;
    justify-content: flex-end;
    margin-right: 5%;
  }
  
  .onlineIcon {
    margin-right: 5%;
  }
.form {
    display: flex;
    border-top: 2px solid #D3D3D3;
  }
  
  .input {
    border: none;
    border-radius: 3px;
    padding: 5%;
    width: 80%;
    font-size: 1.2em;
  }
  
  input:focus, textarea:focus, select:focus{
    outline: none;
  }
  
  .sendButton {
    color: #fff !important;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;
    font-size: 95%;
    background: #1C5AE0;
    padding: 20px;
    display: inline-block;
    border: none;
    border-radius: 1px;
    width: 20%;
    cursor: pointer;
    transition-delay: 3s;
  }

  
.messages {
    padding: 5% 0;
    overflow: auto;
    flex: auto;
  }
.messageBox {
    background: #F3F3F3;
    border-radius: 20px;
    padding: 5px 20px;
    color: white;
    display: inline-block;
    max-width: 80%;
  }
  
  .messageText {
    width: 100%;
    letter-spacing: 0;
    float: left;
    font-size: 1.1em;
    word-wrap: break-word;
  }
  
  .messageText img {
    vertical-align: middle;
  }
  
  .messageContainer {
    display: flex;
    justify-content: flex-end;
    padding: 0 5%;
    margin-top: 3px;
  }
  
  .sentText {
    display: flex;
    align-items: center;
    font-family: Helvetica;
    color: #828282;
    letter-spacing: 0.3px;
  }
  
  .pl-10 {
    padding-left: 10px;
  }
  
  .pr-10 {
    padding-right: 10px;
  }
  
  .justifyStart {
    justify-content: flex-start;
  }
  
  .justifyEnd {
    justify-content: flex-end;
  }
  
  .colorWhite {
    color: white;
  }
  
  .colorDark {
    color: #353535;
  }
  
  .backgroundBlue {
    background: #1C5AE0;
  }
  
  .backgroundLight {
    background: #F3F3F3;
  }
.textContainer {
    display: flex;
    flex-direction: column;
    margin-left: 100px;
    color: white;
    height: 60%;
    justify-content: space-between;
  }
  
  .activeContainer {
    display: flex;
    align-items: center;
    margin-bottom: 50%;
  }
  
  .activeItem {
    display: flex;
    align-items: center;
  }
  
  .activeContainer img {
    padding-left: 10px;
  }
  
  .textContainer h1 {
    margin-bottom: 0px;
  }
  
  @media (min-width: 320px) and (max-width: 1200px) {
    .textContainer {
      display: none;
    }
  }
